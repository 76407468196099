import React, { useEffect, useState, useRef } from "react";
import { Loginwrapper } from "../style";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import OtpInput from "../../OtpInput/OtpInput";
import imgLogin from '../../../images/loginImg.png';
import { isEmpty, isNumber, setStorage } from "../../../Utils/common";
import { Link, useNavigate } from "react-router-dom";
import { sendotpForLogin, verifyotpForLogin } from "../../../Utils/api";
import Alert from "../../ui/Alert";
import RadioButtonConfirm from "../../ui/RadioButtonConfirm";
import MobileInput from "../../OtpInput/MobileInput";

function Login() {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [screen, setScreen] = useState("getOtp");
  const [seconds, setSeconds] = useState(0);
  const [mobile, setMobile] = useState(["","","","","","","","","",""]);
  const [mobileError, setMobileError] = useState("");
  const [loading, setLoading] = useState(false);
  const [accept, setAccept] = useState(false);
  const [acceptError, setAcceptError] = useState("");
  const [message, setMessage] = useState({});
  const [responce, setResponce] = useState({});
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (screen === "getOtp") return;
    const timer = setInterval(() => {
      if (seconds === 0) {
        clearInterval(timer);
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [seconds]);

  const onChanges = (e, type) => {
    const { name, value } = e.target;

    if (type === "sendOtp") {
      if (!isNumber(value) && !isEmpty(value)) return;
      setMobile(value);
      setMobileError("");
    }
  };
  
  

  const sendOtp = () => {
    let mobileNumber = mobile.join("");
    if (mobileNumber.length < 10) {
      setMobileError("The Mobile field must be at least 10 characters in length.");
      return;
    }
    if (!accept) {
      setAcceptError("Please check the checkbox to accept our terms and conditions");
      return;
    }

    const param = {
      mobile: mobileNumber,
      otpFor: "login",
    };

    setLoading(true);
    sendotpForLogin(param).then(resp => {
      setLoading(false);
      if (resp?.data?.Status === 1) {
        setResponce(resp?.data);
        setScreen("otpScreen");
        setSeconds(30);
        setMessage({ type: 'success', msg: resp?.data?.Message, place: "globle" });
      } else {
        setMobile(["","","","","","","","","",""]); // Clear the OTP inputs
        if (inputRefs.current[0]) {
          inputRefs.current[0].focus(); // Focus on the first input field
        }
        let msg = resp?.data?.Message || resp?.data?.error || "An error occurred";
        setMessage({ type: 'error', msg: msg });
      }
    });
  };

  const verifyOTP = () => {
    let otpNumber = otp.join("");

    if (otpNumber.length !== 4) {
      setMessage({ type: 'error', msg: "Please enter 4 digits OTP" });
      return;
    }

    let mobileNumber = mobile.join(""); 

    const param = {
      mobile: mobileNumber,
      lead_id: responce?.lead_id,
      otp: otpNumber,
    };

    setLoading(true);
    verifyotpForLogin(param).then(resp => {
      setLoading(false);

      if (resp?.data?.Status === 1) {
        setResponce(resp?.data);
        setStorage("lead_id", resp?.data?.lead_id);
        setStorage("mobile", mobileNumber); 
        setStorage("token", resp?.data?.token);
        navigate('/my-dashboard');
      } else {
        
        setOtp(["", "", "", ""]);
        if (inputRefs.current[0]) {
          inputRefs.current[0].focus();
        }
        let msg = resp?.data?.Message || resp?.data?.error || "An error occurred";
        setMessage({ type: 'error', msg: msg });
      }
    });
  };


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); 
      verifyOTP(); 
    }
  };

  useEffect(() => {
    if (isEmpty(message)) return;
    const timer = setTimeout(() => {
      setMessage({});
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [message]);

  return (
    <Loginwrapper className="flex justify-center">
      <div className="left">
        <img src={imgLogin} alt="" />
        <div className="textBox">
          <h2>Need an <br /> instant loan</h2>
          <div>Up to ₹100,000</div>
        </div>
      </div>
      <div className="right" style={{ marginRight: "110px", maxWidth: "413px", minHeight: "417px", backgroundColor: "#f5f5fa", borderRadius: "52px" }}>
        <div className="form" style={{ maxWidth: "400px" }}>
          <Alert setMessage={setMessage} message={message} />
          <h2 style={{ fontSize: "26px" }}>Apply for a Personal Loan</h2>
          {screen === "getOtp" ? (
            <>
              <p style={{ fontSize: "13px", fontWeight: "600", marginBottom: "30px" }}>Enter your 10 digit mobile number to get started</p>
              <div>
                

              <div className="otpBox">
                <MobileInput
                  name="mobile"
                  setOtp={setMobile}
                  otp={mobile}
                  isDisable={false}
                  error={mobileError}
                  onChange={(e) => onChanges(e, "sendOtp")}
                  placeholder="*"
                  onKeyDown={handleKeyDown}
                  inputRefs={inputRefs} // Pass inputRefs to OtpInput
                />
              </div>
                <div className="flex confirm" style={{ fontSize: "13px", fontWeight: "600", textAlign: "justify", marginTop: "17px" }}>
                  <RadioButtonConfirm
                    label={<>By selecting this checkbox, I authorize the receipt of digital communications, such as phone calls, SMS, emails, and WhatsApp messages, at the phone number, email address, and app I have provided from salaryontime. Furthermore, I acknowledge that I have reviewed and accept the <Link to="termsandconditions">Terms and Conditions</Link>, <Link to="termsandconditions">Privacy Policy</Link>.</>}
                    name="radio"
                    checked={accept}
                    error={acceptError}
                    onChange={() => {
                      setAccept(!accept);
                      setAcceptError("");
                    }}
                  />
                </div>
                <div className="button">
                  <Button title="Get OTP" onClick={sendOtp} loading={loading} />
                </div>
              </div>
            </>
          ) : (
            <>
              <p>Please enter the OTP to unlock your next step.</p>
              <div className="otpBox">
                <OtpInput
                  type="text"
                  setOtp={setOtp}
                  otp={otp}
                  isDisable={false}
                  placeholder="*"
                  onKeyDown={handleKeyDown}
                  inputRefs={inputRefs} // Pass inputRefs to OtpInput
                />
              </div>
              <div className="button">
                <Button title="Verify OTP" onClick={verifyOTP} loading={loading} />
              </div>
              <div className="resend">
                {seconds > 0 ? (
                  <p className="none">
                    Didn't receive the OTP? <span style={{ color: "red", fontWeight: 600 }}>00:{seconds}s</span>
                  </p>
                ) : (
                  <p className="none" style={{ color: 'green', cursor: 'pointer', fontSize: '14px',fontFamily:'Roboto, sans-serif' }} onClick={!loading ? sendOtp : () => { }}>
                    Press to resend OTP
                  </p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Loginwrapper>
  );
}

export default Login;
