



import React, { useContext, useEffect, useRef, useState } from 'react';

import { BoxWrapper } from '../../../style';
import arrowIcon from "../../../images/arrow.png"
import { FormWrapper } from '../../../components/loan/style';
import Button from '../../../components/ui/Button';
import Alert from '../../../components/ui/Alert';

import { useNavigate } from 'react-router-dom';
import { getStorage, goBack, isEmpty, setStorage } from '../../../Utils/common';

import PictureUpload from '../../../components/PictureUpload/PictureUpload';
import { uploadProfilePhoto,getDashboardData } from '../../../Utils/api';
import ContextDashboard from '../../../Context/ContextDashboard';
import ProgressBar from "../../../components/ProgressBar/ProgressBar";



function UploadUtilitybill() {
    const [loading, setLoading] = useState(false);
    const [responce,setResponce] = useState({});
    const [setSide,setSetSide] = useState("one");
    const [utilityone64,setUtilityOne64] = useState("");
    const [utility1,setUtility1] = useState("");
    const [utility2,setUtility2] = useState("");
    const [utility3,setUtility3] = useState("");
    
    const [utilitytwo64,setUtilityTwo64] = useState("");
    const [utilitythree64,setUtilityThree64] = useState("");
    const [message,setMessage] = useState("");
    const [showSteps, setShowSteps] = useState(-1);
    const [toggle, setToggle] = useState(true);
    const [progressBar, setProgressBar] = useState(0);

    const navigate = useNavigate();
    const {logout,profileData,getProfileDaital,setps} = useContext(ContextDashboard);


    useEffect(()=>{
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
             const data = base64.split(",");
              let img = data[data.length -1];
        setUtilityOne64(img)
       
      };
  
      if (utility1) {

      
        if(typeof utility1 === "string"){
          const blob = new Blob([utility1]);
          reader.readAsDataURL(blob);
        }else{
          reader.readAsDataURL(utility1);
        }
      }
    },[utility1])

    useEffect(()=>{
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
             const data = base64.split(",");
              let img = data[data.length -1];
        setUtilityTwo64(img)
       
      };
  
      if (utility2) {

      
        if(typeof utility2 === "string"){
          const blob = new Blob([utility2]);
          reader.readAsDataURL(blob);
        }else{
          reader.readAsDataURL(utility2);
        }
      }
    },[utility2])

    useEffect(()=>{
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
             const data = base64.split(",");
              let img = data[data.length -1];
        setUtilityThree64(img)
       
      };
  
      if (utility3) {

      
        if(typeof utility3 === "string"){
          const blob = new Blob([utility3]);
          reader.readAsDataURL(blob);
        }else{
          reader.readAsDataURL(utility3);
        }
      }
    },[utility3])

    useEffect(() => {
      console.log("Current setSide:", setSide);
  }, [setSide]);

  const submit = () => {
    if ((utility1 === "") && (utilityone64 === "") && (setSide === "one")) {
        setMessage({ type: 'error', msg: "Please select utility bill 1", place: "local" });
        return;
    }
    if ((utility2 === "") && (utilitytwo64 === "") && (setSide === "two")) {
        setMessage({ type: 'error', msg: "Please select utility bill 2", place: "local" });
        return;
    }
    if ((utility3 === "") && (utilitythree64 === "") && (setSide === "three")) {
        setMessage({ type: 'error', msg: "Please select utility bill 3", place: "local" });
        return;
    }

    setMessage({});
    let ext = "JPEG";
    if (typeof utility1 === "object") {
        let extArr = utility1.name.split('.');
        ext = extArr[extArr.length - 1].toUpperCase();
    }

    const param = {
        lead_id: getStorage("lead_id") || "",
        token: getStorage("token") || "",
        ext: ext,
        password: "N/A",
    };

    if (setSide === "one") {
        param.docs_id = "8";
        param.file = utilityone64;
    } else if (setSide === "two") {
        param.docs_id = "101";
        param.file = utilitytwo64;
    } else {
        param.docs_id = "102";
        param.file = utilitythree64;
    }

    setLoading(true);
    uploadProfilePhoto(param).then(resp => {
        setLoading(false);
        console.log(resp?.data);
        if (resp?.data?.Status === 1) {
            setResponce(resp?.data);
            setMessage({ type: 'success', msg: resp?.data?.Message, place: "global" });
            
            // Proceed to the next step based on current setSide
            if (setSide === "one") {
                setSetSide("two");
            } else if (setSide === "two") {
                setSetSide("three");
            } else {
                getProfileDaital();
                getDashboardData({ token: getStorage("token") || "", lead_id: getStorage("lead_id") || "", mobile: getStorage("mobile") || "" }).then(dashboardResp => {
                  const userType = dashboardResp?.data?.Steps?.data.user_type;
                  setProgressBar(dashboardResp?.data?.Steps?.steps?.step_complete_percent);
        
                  // Navigate based on user_type
                  if (userType === 'REPEAT' || userType === 'UNPAID-REPEAT') {
                    navigate("/my-dashboard/calculate-loan");
                  } else {
                    navigate("/my-dashboard/about-your-company");
                  }
                })
                
            }
        } else if (resp?.data?.Status === 5) {
            logout();
        } else {
            setMessage({ type: 'error', msg: resp?.data?.Message });
        }
    });
};

      useEffect(() => {

        const params = {
            lead_id: getStorage("lead_id") || "",
            token: getStorage("token") || "",
            mobile: getStorage("mobile") || "",
        };

        getDashboardData(params).then(resp => {
            if (resp?.data?.Status === 1) {
                const dashboardData = resp?.data?.Steps?.data || {};
                if (dashboardData) {
                   
                    setProgressBar(resp?.data?.Steps?.steps?.step_complete_percent);
                }
            } else if (resp?.data?.Status === 5) {
                logout();
            }
        });
    }, [logout]);

    useEffect(() => {
      if (!isEmpty(setps)) {
          checkStep(setps);
      }
  }, [setps]);

  const checkStep = (data) => {
      const steps = (data?.step_stage - 1);
      if (data?.step_complete_percent === 100) {
          setToggle(false);
      }
      setShowSteps(steps);
  };


  return (
    <><ProgressBar value={`${progressBar}%`}>
    
      <div >
       
      </div>
    <></>
  </ProgressBar>
    <br />
       <BoxWrapper  className="w100" >
     

        <div className="formmainBox flex">
          <div className="left">
            <div className='center gap4 pointer' onClick={()=>goBack(navigate,"/my-dashboard/upload-salaryslip")} >
                <img src={arrowIcon} alt="" /> <span>Back</span>
            </div>
          </div>
          <div className="right">
            <h2>Upload Your Utility Bill</h2>
            <p>Upload your utility bill to verify your details"</p>
              
            <FormWrapper>
            <Alert setMessage={setMessage} message={message}  />
 
            <div className="inputBox">
             {setSide === "one"&& <div >
                <h2 className='subheading small'>Electricity Bill</h2>
                 <PictureUpload  setImage={setUtility1}  image={utility1}/>
            
             </div>}
            {setSide === "two"&&              <div>
             <h2 className='subheading small'>Wifi Bill</h2>
                 <PictureUpload  setImage={setUtility2}  image={utility2}/>
            
             </div>}
             {setSide === "three"&&              <div>
             <h2 className='subheading small'>Gas Bill</h2>
                 <PictureUpload  setImage={setUtility3}  image={utility3}/>
            
             </div>}
              </div>
             
              
             
              <div className="button">
              <Button title="Continue" onClick={submit} loading={loading} />
              </div>
            </FormWrapper>
           
          </div>
        </div>

       </BoxWrapper>
    </>
  )
}

export default UploadUtilitybill;